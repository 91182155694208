<template>
    <div class="init">
        <el-form :model="fromValue" label-width="auto"  ref='form_' class="fromClass"
            :rules='rules' label-position="top">
            <platformCheck @setTenantId='setTenantId_' :tenantId_1='fromValue.tenantId'/>
            <el-form-item :label="$t('adminDict.name2')" prop="dictType">
                <el-input v-model="fromValue.dictType" :placeholder="$t('inputTit.shuru')" :disabled='style_ === 1'/>
            </el-form-item>

            <el-form-item :label="$t('adminDict.name4')" prop="systemFlag">
                <el-select v-model="fromValue.systemFlag" :placeholder="$t('inputTit.xuanze')">
                    <el-option :label="item.label" :value="item.value" v-for='item in dicArray' :key = 'item.appId'  />
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('adminDict.name3')" prop="description">
                <el-input v-model="fromValue.description" type="textarea" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>

            <el-form-item :label="$t('adminDict.name5')" prop="remarks">
                <el-input v-model="fromValue.remarks" :placeholder="$t('inputTit.shuru')" type="textarea"/>
            </el-form-item>
            <el-form-item :label="$t('adminDict.name6')" prop="createTime" v-if='style_ === 1'>
                <el-input v-model="fromValue.createTime" :placeholder="$t('inputTit.shuru')" disabled/>
            </el-form-item>
        </el-form>
    </div>
</template>
<script setup>
    import { ref,defineExpose,reactive,defineEmits,getCurrentInstance } from 'vue'
    // import addrView from './addr.vue'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import addRules from './addRules'
    import platformCheck from '@/components/platformCheck.vue'  
    const { $t } = getCurrentInstance().proxy;
    import { getDataAboutTextArray } from '@/utils/dataBind/parmsView'
    
    const fromValue = reactive({ 
        id: '',dictType: '',description:'',
        remarks: '',systemFlag: '',createTime: '',tenantId:''
    })

    const form_ = ref(null)
    const rules = ref(null);
    rules.value = addRules.mainRules
    const style_ = ref(0)
    const dicArray = ref([]);dicArray.value = getDataAboutTextArray(1)

    const emit = defineEmits(['successFun','closeFun'])

    const setTenantId_ = (e)=>{
        fromValue.tenantId = e
    }
    
    const completeFun = ()=>{
        if (!form_.value) return
        form_.value.validate((e) => {
            if(e){
                if(style_.value === 0){
                    let obj_ = JSON.parse(JSON.stringify(fromValue))
                    delete obj_.id
                    Api_.addDict(obj_).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succAdd'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }else{
                    Api_.updateDict(fromValue).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succUpdate'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }
            }
        })
    }
    const init_ = (e,obj)=>{//初始化数据
        style_.value = e;
        if(obj){
            fromValue.id = obj.id;
            fromValue.dictType = obj.dictType;
            fromValue.description = obj.description;
            fromValue.remarks = obj.remarks;
            fromValue.systemFlag = obj.systemFlag;
            fromValue.createTime = obj.createTime;
            fromValue.tenantId = obj.tenantId;
        }else{
            for(let index in fromValue){
                if(index !== 'tenantId'){
                    fromValue[index] = ''
                }
            }
        }
    }
    defineExpose({init_,completeFun})
</script>